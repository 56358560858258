


















import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueZoomer from 'vue-zoomer';
import PictureSizes from '../models/Picture/PictureSizes';
Vue.use(VueZoomer);
export default Vue.extend({
  name: 'Picture',
  data: () => ({
    isFailedImg: false
  }),
  components: {},
  props: ['pictureId'],
  computed: {
    ...mapGetters({
      getPicturesrcByIdAndSize: 'pictures/srcByIdAndSize'
    }),
    imgSrc(): void {
      return this.isFailedImg
        ? `${process.env.BASE_URL}img/not-found.png`
        : this.getPicturesrcByIdAndSize(this.pictureId);
    },
    zoomerStyle(): any {
      return {
        width: '100vw',
        height: '100vh'
      };
    }
  },
  methods: {
    handleExitImgWithZoom() {
      this.$router.go(-1);
    },
    onSrcError(event: any) {
      this.isFailedImg = true;
    }
  }
});
